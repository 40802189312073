@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&family=Yellowtail&display=swap);

body {
	background-color: rgba(0, 0, 0, 0.8);
	min-width: 340px;
}

*::-webkit-scrollbar {
	width: 0.5rem;
}

*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

.wrapper {
	width: 86%;
	height: 98vh;
	margin: 2vh 7% 0 7%;
	display: flex;
	justify-content: stretch;
	min-width: 760px;
}

/* General sidebar */

#deviceDropDownButton {
	display: none;
}

.sidebar {
	width: 300px;
	min-width: 300px;
	background-color: rgb(32, 32, 32);
	padding: 30px 30px 20px 30px;
	height: 99%;
	z-index: 10;
}

.sidebar__logo {
	font-family: 'Yellowtail', cursive;
	font-size: 40px;
	color: rgb(243, 156, 18);
	margin-left: -2px;
}

.yellow {
	color: rgb(243, 156, 18) !important;
}

.sidebar__sublogo {
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	letter-spacing: 0.05em;
	color: rgb(255, 255, 255, 0.7);
	margin-top: 12px;
}

.sidebar__title {
	font-family: 'Oswald', sans-serif;
	font-size: 16px;
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	padding-top: 10px;
}

.nav {
	margin-top: 40px;
}

.nav__item {
	cursor: pointer;
}

.nav__item:hover {
	color: rgb(243, 156, 18, 0.5);
}

.margin--break {
	margin-top: 30px;
}

.nav__item--active {
	color: rgb(243, 156, 18) !important;
}

.social {
	display: flex;
	align-items: baseline;
	justify-content: start;
	margin-top: 16px;
}

.social__link {
	margin-right: 30px;
	width: 25px;
	text-align: left;
}

.social__item {
	cursor: pointer;
	color: rgb(255, 255, 255);
	font-size: 18px;
	width: 18px;
}

.social__item:hover {
	color: rgb(243, 156, 18);
}

/* General main section */

.main {
	padding: 30px 30px 20px 40px;
	width: 100%;
	vertical-align: top;
	background-color: rgb(42, 42, 42);
	height: 99%;
	background: url(../images/main_bg_texture_small.png) repeat;

	overflow-y: auto;
}

.main__title {
	font-family: 'Oswald', sans-serif;
	font-size: 48px;
	color: rgb(243, 156, 18);
	text-transform: uppercase;
}

.main__subtitle {
	font-family: 'Oswald', sans-serif;
	font-size: 32px;
	color: rgb(255, 255, 255, 0.8);
	text-transform: uppercase;
	margin-top: 5px;
}

/* About Me section */

.aboutme__photo__container {
	display: inline-block;
	position: relative;
}

.aboutme__photo {
	min-width: 210px;
	max-width: 350px;
	max-height: 520px;
}

.aboutme__photo__caption {
	font-family: 'Open Sans', sans-serif;
	font-size: 10px;
	color: rgb(255, 255, 255, 0.4);
	position: absolute;
	top: 5px;
	right: 5px;
	opacity: 0;
	transition: opacity 0.5s;
	text-align: right;
}

.aboutme__info {
	vertical-align: top;
	max-width: 400px;
	padding-left: 24px;
	align-self: flex-end;
}

.common__text {
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	color: rgb(255, 255, 255, 0.7);
	line-height: 1.6;
	margin-bottom: 5px;
}

.aboutme__citation {
	font-family: 'Oswald', sans-serif;
	font-size: 1rem;
	color: rgb(243, 156, 18);
	text-transform: uppercase;
	line-height: 1.5;
}

.white {
	color: rgb(211, 200, 182);
}

.ml2 {
	margin-left: 1rem;
}

.mt1 {
	margin-top: 0.5rem;
}

.aboutme__citation__author {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	color: rgb(255, 255, 255, 0.7);
	line-height: 1.5;
	margin: 5px 0 16px 0;
}

.bold {
	font-weight: 700;
	color: rgb(243, 156, 18, 0.8);
	padding: 0 0.3rem;
}

.italic {
	font-style: italic;
}

.button {
	background-color: rgb(243, 156, 18, 0.8);
	color: rgb(255, 255, 255);
	width: 128px;
	height: 43px;
	cursor: pointer;
	transition: all 0.2s;
	text-decoration: none;
	font-family: 'Oswald', sans-serif;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
}

.button:hover {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
	background-color: rgb(255, 255, 255, 0.7);
	color: rgb(243, 156, 18);
}

/* Media Presents section */

.mediaPresents__container,
.articles__container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
	grid-gap: 20px;
	/* overflow-y: scroll; */
}

.mediaPresent {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.mediaPresent__iframe {
	width: 100%;
	min-height: 16em;
}

.mediaPresent__link {
	margin-top: 15px;
	color: rgb(255, 255, 255);
	overflow: hidden;
}

.mediaPresent__link:hover {
	color: rgb(243, 156, 18);
}

.sub_button {
	margin-top: 0.1rem;
	color: rgb(255, 255, 255);
	cursor: pointer;
	text-align: center;
}

.sub_button:hover {
	color: rgb(243, 156, 18);
}

.cover {
	position: relative;
	background: 0% 0% / cover;
}

.cover__play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	font-size: 4em;
	color: rgb(0, 0, 0, 0.8);
	transition: color 0.1s;
}

.cover__play:hover {
	color: rgb(255, 0, 0);
}

/* contact section */

.main__subtext {
	margin-top: 30px;
	max-width: 700px;
}

.contact__form {
	max-width: 350px;
	margin-top: 40px;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	color: rgb(132, 132, 132);
	text-align: left;
	position: relative;
	width: 330px;
}

.contact__input {
	display: block;
	margin-bottom: 20px;
	padding-left: 35px;
	width: 295px;
	text-transform: capitalize;
	background-color: rgb(32, 32, 32, 0.7);
	height: 40px;
}

.contact__textarea {
	background-color: rgb(32, 32, 32, 0.7);
	height: 171px;
	width: 310px;
	padding: 10px;
}

.contact__input__icon {
	position: absolute;
	transform: translateY(-50%);
	margin: 20px 10px 0 10px;
}

/* Publications section */

/* .publications__container {
	overflow-y: scroll;
} */

.publication {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 95%;
	margin: 15px 0;
}

.publication__paragraph {
	width: 80%;
	padding-left: 0.5rem;
}

.publication__span {
	margin-right: 3px;
}

.publication__year {
	font-weight: 800;
	color: rgb(243, 156, 18, 0.5);
}

.publication__journal__link,
.publication__journal__empty-link {
	text-decoration: underline;
	transition: all 0.2s;
}

.publication__journal__empty-link {
	text-decoration: underline;
	transition: all 0.2s;
}

.publication__line {
	width: 80%;
	height: 1px;
	margin: 15px 15% 10px 5%;
	background-color: rgb(32, 32, 32);
}

/* new styling */

.photo-text-container {
	display: flex;
	padding: 20px 0;
}

.current-section {
	margin-top: 2.5rem;
	padding: 0 1rem 2rem;
	border-bottom: 1px solid rgb(128, 128, 128, 0.5);
}

.current-section__title {
	margin-bottom: 1rem;
	width: fit-content;
}

.award-section__title {
	width: fit-content;
}

.hovering {
	opacity: 0.9;
	border-bottom: 1px solid transparent;
	transition: all 0.2s ease-in-out;
}

.hovering:hover,
.hovering:active {
	opacity: 1;
	border-bottom: 1px solid rgb(243, 156, 18, 0.5);
}

.sidebar__email {
	font-family: 'Oswald', sans-serif;
	font-size: 0.8rem;
	width: fit-content;
	color: rgb(243, 156, 18);
	text-transform: lowercase;
	letter-spacing: 0.05rem;
	margin-top: 0.5rem;
	padding: 0.15rem 0;
	opacity: 0.9;
	border-bottom: 1px solid transparent;
	transition: all 0.2s ease-in-out;
}

.common__link {
	color: rgb(243, 156, 18);
}

.sidebar__email:hover,
.sidebar__email:active,
.common__link:hover,
.common__link:active {
	opacity: 1;
	border-bottom: 1px solid rgb(243, 156, 18);
}

.line {
	/* width: 80%; */
	/* height: 1px; */
	/* background-color: rgb(243, 156, 18); */
	margin: 2rem 0;
}

.use {
	font-style: oblique;
	color: rgb(245, 131, 25, 0.5);
	font-size: 0.8rem;
}
