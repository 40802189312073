@media screen and (max-width: 1120px) {
	.aboutme__citation {
		font-size: 14px;
	}
}

@media screen and (max-width: 968px) /* and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) */ {
	*:focus {
		outline: 0;
	}

	.wrapper {
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		min-width: 340px;
	}

	.sidebar {
		width: 100%;
		min-width: unset;
		padding: 10px;
		position: relative;
	}

	.sidebar__logo,
	.sidebar__sublogo,
	#sidebarSocial,
	.social {
		display: none;
	}

	.hideble {
		display: none;
	}

	#deviceDropDownButton {
		display: block;
	}

	.device__dropDownButton {
		cursor: pointer;
		font-size: 24px;
		color: rgb(255, 255, 255);
		margin-left: 15px;

  background-color: rgb(32, 32, 32);;
  height: 24px;
	}

	.nav {
		margin-top: unset;
		display: none;
		background-color: rgb(32, 32, 32, 0.95);
		position: absolute;
		padding: 16px;
		top: 45px;
		left: 20px;
		width: 200px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	}

	.sidebar__title {
		font-size: 20px;
	}

	.main {
		margin-top: 10px;
		padding: 0 10px;
		height: unset;
		width: 100%;
	}

	/* Media Presents section */

	.mediaPresents__container {
		grid-template-columns: 1fr;
		grid-gap: 15px;
		overflow-y: unset;
	}
}

@media screen and (max-width: 440px) {
	.rotate {
		transform: rotate(-90deg);
	}

	.publication {
		width: 100%;
	}

	.publication__paragraph {
		width: 95%;
	}

	.photo-text-container {
		flex-direction: column;
	}

	.aboutme__info {
		vertical-align: top;
		max-width: 400px;
		padding-left: 0;
		align-self: center;
		padding-top: 16px;
	}

	.main {
		padding-bottom: 16px;
	}

	.aboutme__photo {
		min-width: none;
		width: 50%;
	}
}
